<template>
  <div class="no-photos">
    <div class="no-photos-content">
      <div class="no-photos-actions mb-3">
        <div class="d-flex mb-3 align-items-center">
          <button
            @click="backToPreviousPage"
            class="default-btn back-btn"
          ></button>
          <h1 class="page-title font-weight-bold fs-24 mb-0">PHOTOS</h1>
        </div>
        <div class="no-photos-actions-content">
          <span class="actions-content-title">Let's add some photos!</span>
          <div
            class="no-photos-actions-card mb-3 mt-2"
            :class="isNZ && !isMobile ? 'disabled' : ''"
          >
          <div class="actions-card-header">
            <span class="action">Guided Camera</span>
            <span class="recommended">RECOMMENDED</span>
          </div>
            <p class="action-description">
              Use our step-by-step guided camera to capture your car photos.
            </p>
            <div class="nz-tooltip">
              Login using a mobile device to access the guided camera mode.
            </div>
            <button class="default-btn next-btn" :disabled="isNZ && !isMobile" v-on:click="openGuidedCamera">
              {{ isMobile ? "" : "Get started" }}
            </button>
          </div>
          <div class="no-photos-actions-card">
            <div class="actions-card-header">
              <span class="action">Upload photos from device</span>
            </div>
            <p class="action-description">
              Upload photos from your device with ease.
            </p>
            <button class="default-btn next-btn" v-on:click="openUpdatePhoto">
              {{ isMobile ? "" : "Add photos" }}
            </button>
          </div>
        </div>
      </div>
      <div class="photo-guide-card">
        <img src="@/assets/images/photo_upload_2.0.png" width="100%" />
        <div class="grid-container">
          <div class="grid-item">
            <p class="photo-guide-card-desc">
              Add at least 10 photos and increase your chance of selling by 55%!
              *
            </p>
          </div>
          <div class="grid-item">
            <button class="photo-guide-button" v-on:click="goToPhotoGuide">
              Read photo guide
            </button>
          </div>
          <div class="grid-item">
            <p class="fs-12 font-weight-thin" style="width: 100%">
              * Compared to a car listing that has up to 5 photos.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "NoPhotos",
  props: {
    initImgUploadCamera: { type: Function },
    updateUrl: { type: Function },
  },
  data() {
    return {
      isMobile: true,
    };
  },
  computed: {
    ...mapState({
      photoTipsBanner: (state) =>
        state.commonVariables.copyBlocks.photoTipsBanner,
      hasPhoto: (state) => state.photo.hasPhoto,
      authenticateData: (state) => state.authenticateSeller,
    }),
    mobileMediaQuery: function () {
      return window.matchMedia("(min-width: 768px)");
    },
    isNZ: function () {
      return parseInt(this.authenticateData.locid) === 2;
    },
  },
  methods: {
    backToPreviousPage() {
      this.$router.push({ name: "navigation", query: this.$route.query });
    },
    setHasPhoto() {
      this.$store.dispatch("hasPhoto", !this.hasPhoto);
    },
    goToPhotoGuide() {
      window.open(this.photoTipsBanner.photoLink, "_blank");
    },
    openGuidedCamera() {
      if (this.isMobile) {
        this.initImgUploadCamera();
      } else if (!this.isNZ) {
        this.$bvModal.show("modal-guided-camera-sms");
      }
    },
    openUpdatePhoto() {
      this.setHasPhoto();
      this.$store.dispatch("setGuideCameraUsed", false);
      this.updateUrl();
    },
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>
<style lang="scss">
.no-photos {
  text-align: left;
  padding: 16px;

  .no-photos-content {
    .no-photos-actions {
      .page-title {
        color: var(--primary-color);
        line-height: 34px;
      }

      .default-btn {
        position: relative;
        width: 32px;
        height: 34px;
        border-radius: 8px !important;
        outline: none;
        font-size: 18px;
        &:before {
          content: "";
          left: 57%;
          transform: translate(-50%, -50%) rotate(315deg);
          border: solid;
          border-width: 0 3px 3px 0;
          position: absolute;
          top: 50%;
          height: 9px;
          width: 9px;
        }
        &.back-btn {
          border: 1px solid var(--primary-color);
          background-color: #ffffff;
          color: var(--primary-color);
          margin-right: 16px;
          &:before {
            transform: translate(-50%, -50%) rotate(135deg);
          }
        }
      }

      .no-photos-actions-content {
        .actions-content-title {
          color: #455a64;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
        }

        .no-photos-actions-card {
          background-color: #f1f1f3;
          border-radius: 16px;
          padding: 16px;
          position: relative;

          .actions-card-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .action {
              font-size: 18px;
            }

            .recommended {
              padding: 5px 8px;
              background-color: #6df4af;
              border-radius: 8px;
              font-family: "Avenir Next LT W05 Regular";
              font-size: 10px;
              font-weight: 600;
              text-transform: uppercase;
              line-height: 1;
              color: #296546;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .action-description {
            font-family: "Avenir Next LT W05 Regular" !important;
            margin-top: 6px;
            margin-bottom: 0;
            max-width: 90%;
          }

          .default-btn.next-btn {
            background-color: transparent;
            color: var(--primary-color);
            position: absolute;
            right: 5%;
            top: 50%;
            transform: translateY(-50%);
            border: none;
          }

          &.disabled {
            position: relative;
            .default-btn.next-btn {
              background-color: #1F825280 !important;
            }
          }

          .nz-tooltip {
            position: absolute;
            top: -48px;
            left: 70%;
            width: 274px;
            background-color: #2e312e;
            color: #f0f1ec;
            border-radius: 4px;
            padding: 4px 8px 4px 8px;
            font-family: "Avenir Next LT W05 Regular";
            font-size: 12px;
            font-weight: 400;
            display: none;
          }

          &.disabled:hover .nz-tooltip {
            display: block;
          }
        }
      }
    }

    .photo-guide-card {
      padding: 16px;
      border: 1px solid #1f8252;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;

      img {
        max-width: 380px;
      }

      .grid-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;

        .photo-guide-card-desc {
          color: #414942;
          font-size: 14px;
          margin-bottom: 0;
          width: 100%;
        }

        .photo-guide-button {
          width: 100%;
          padding: 10px;
          border: 1px solid #1f8252;
          border-radius: 4px !important;
          background-color: white;
          color: #1f8252;
          font-family: "Avenir Next LT W05 Regular" !important;
          font-size: 14px;
          font-weight: 600 !important;
          outline: none;
        }
      }
    }
  }
}

@media screen and(min-width: 768px) {
  .no-photos {
    margin-top: 15px;
    .no-photos-content {
      .no-photos-actions {
        margin-bottom: 60px !important;

        .no-photos-actions-content {
          .no-photos-actions-card {
            padding: 20px;
            .default-btn.next-btn {
               width: 130px;
              height: 35px;
              border-radius: 4px !important;
              background-color: #1f8252;
              color: white;
              font-size: 16px;
              font-family: "Avenir Next LT W05 Regular" !important;
              font-weight: 700 !important;
              &:before {
                border-width: 0;
              }
            }
          }
        }
      }
      .photo-guide-card {
        flex-direction: row;
        justify-content: space-around;

        img {
          width: auto;
        }

        .grid-container {
          .photo-guide-card-desc {
            font-size: 16px;
          }
          .photo-guide-button {
            padding: 5px;
            font-size: 16px;
            font-weight: 700 !important;
          }
        }
      }
    }
  }
}

@media screen and(min-width: 992px) {
  .no-photos .no-photos-content {
    .no-photos-actions .no-photos-actions-content .no-photos-actions-card {
      padding: 36px;

      .default-btn.next-btn {
        width: 184px;
      }

      .actions-card-header {
        .action {
          font-size: 20px;
        }

        .recommended {
          font-size: 12px;
        }
      }
    }

    .photo-guide-card {
      border-color: #E6E6E6;
      .grid-container {
        grid-template-columns: 59% 33%;
      }
    }
  }
}

@media screen and(min-width: 1200px) {
  .no-photos .no-photos-content .photo-guide-card .grid-container {
    grid-template-columns: 67% 28%;
  }
}
</style>